import React, { useState } from "react"

export const appContext = React.createContext()

const Provider = props => {
  const [employee, setEmployee] = useState(null)
  const [translations, setTranslations] = useState(null)
  const [language, setLanguage] = useState("en-us")
  const [brand, setBrand] = useState("QL")

  return (
    <appContext.Provider
      value={{
        employee,
        changeEmployee: payload => setEmployee(payload),
        language,
        setLanguage: lang => setLanguage(lang),
        brand,
        setBrand: brand => setBrand(brand),
        translations,
        setTranslations: translations => setTranslations(translations)
      }}
    >
      {props.children}
    </appContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
